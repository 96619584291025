:root {
    --clr1-hue: 212;
    --clr1-saturation: 100%;
    --clr1-lightness: 5%;
    --clr1: hsl(var(--clr1-hue), var(--clr1-saturation), var(--clr1-lightness));

    --clr2: rgb(255, 174, 0);

    --lightness0: 0%;
    --lightness1: 10%;
    --lightness2: 20%;
    --lightness3: 30%;
    --lightness4: 40%;
    --lightness5: 50%;
    --lightness6: 60%;
    --lightness7: 70%;
    --lightness8: 80%;
    --lightness9: 90%;
    --lightness10: 100%;

    --transition1: all 0.1s ease-in-out;
    --transition2: all 0.2s ease-in-out;
    --transition3: all 0.3s ease-in-out;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--clr1);
    color: hsl(0, 0%, var(--lightness10));
    max-width: 100vw;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    margin: 0rem auto;
}

main > article {
    margin: 3rem auto;
    width: min(980px, 95vw);
}

main > article > img {
    width: 100%;
}
